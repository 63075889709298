<script setup lang="ts"></script>

<template>
  <div>
    <h1 class="text-3xl font-semibold text-tiki-black">Purchase Data</h1>
    <p class="text-lg text-tiki-gray font-normal my-3">
      The first step is to poke around and create an estimate for the data you need.
    </p>
    <hr class="text-tiki-black/10 my-6" />
  </div>
</template>
